(function($, generic, ServiceBus, ServiceBusTopics) {

generic.checkout = {};

/**
 * The cart is a singleton.  Multicart functionality needs to be extended,
 * where this singleton can provide a single reference to manage n carts.
 *
 * @class Cart
 * @namespace generic.checkout.cart
 *
 * @requires generic.cookie, generic.jsonrpc, generic.Hash
 *
 * @returns singleton cart object
 *
 */
generic.checkout.Cart = (function() {

    /**
     * @private declared dependencies of other js modules
     */
    var Hash = generic.Hash, JsonRpc = generic.jsonrpc, Cookie = generic.cookie;

    /**
     * @private singleton
     */
    var cart;

    /**
     * @private    private classes for mixin to service final api {}
     */
    var Properties = {

        setCookie: false

    },
    Containers = {
        order: new Hash(),
        payments: new Array(),
        carts: new Hash(),
        items: new Array(),
        samples: new Array()
    },
    CartData = {
        totalShoppedItems: 0,
        totalItems: 0
    },
    /**
     * @constant
     */
    CartConstants = {

        transactionParams: {
            transactionFields: {
                "trans_fields" : ["TRANS_ID", "payments"]
            },
            paymentFields: {
                "payment_fields" : ["address", "PAYMENT_TYPE", "PAYMENT_AMOUNT", "TRANS_PAYMENT_ID"]
            },
            orderFields: {
                "order_fields" : ["items", "samples", "address", "TRANS_ORDER_ID"]
            }
        },
        itemTypes: {
            "cart" : {
                 "id": "SKU_BASE_ID",
                 "_SUBMIT" : "cart"
             },
             "GiftCard" : {
                 "id": "GiftCard",
                 "_SUBMIT" : "giftcard"
             },
             "collection" : {
                 "id": "SKU_BASE_ID",
                 "_SUBMIT" : "collection.items"
             },
             "kit" : {
                 "id": "COLLECTION_ID",
                 "_SUBMIT" : "alter_collection"
             },
             "replenishment" : {
                 "id": "SKU_BASE_ID",
                 "_SUBMIT" : "alter_replenishment"
             },
             "favorites" : {
                 "id": "SKU_BASE_ID",
                 "_SUBMIT" : "alter_collection"
             }
        }
    },
    Ops = {
        /**
         * @private update cart state
         */
        _updateCartData: function(data){
            // console.log("generic.checkout.cart._updateCartData");
            var self = this;
            this.data = data;
            this.totalItems = data.items_count;
            this.defaultCartId = data.default_cart_id;
            // ATTENTION $A needs work
            this.payments = (data.trans && data.trans.payments) ? $A(data.trans.payments) : null;
            this.order = data.order;

            // contents and sample_contents mirror the sku by qty hashes
            this.order.contents = new Hash();
            this.order.sample_contents = new Hash();

            if (this.order.items != null) {
				this.order.items = $.map(this.order.items,
					function (ele) { // filter out nulls
						return (ele == null ? null : ele)
					}
				);
            }

            var items = this.order.items || null;
            var totalShoppedItems = 0;
            if (items != null) {
                $.each(items, function(){
                    if (!this) { return; }
                    totalShoppedItems+=this.ITEM_QUANTITY;

                    // set up contents by cart hashes
                    var cartID = this.CART_ID;
                    var cart = self.carts.get(cartID);
                    if (!cart) {
                        self.carts.set(cartID, new Hash());
                        cart = self.carts.get(cartID);
                        cart.set('contents', new Hash());
                    }
                    var id = this['sku.SKU_BASE_ID'] ? this['sku.SKU_BASE_ID'] : this.COLLECTION_ID;
                    cart.get('contents').set(id, this.ITEM_QUANTITY);

                    // compute per-unit tax (replace this with field from JSONRPC result when available)
                    var unitTax = this.APPLIED_TAX/this.ITEM_QUANTITY;
                    this.UNIT_TAX = unitTax;

                    // set up order contents hash (spans carts)
                    if (this.itemType.toLowerCase() == 'skuitem') {
                        var key = this['sku.SKU_BASE_ID'];
                        var qty = this.ITEM_QUANTITY;
                        //error self.order.contents.set(key, qty);
                        self.order.contents[key] = qty;
                    } else if (this.itemType.toLowerCase() == 'kititem') {
                        var key = this.COLLECTION_ID;
                        var qty = this.ITEM_QUANTITY;
                        self.order.contents.set(key,qty);
                    } else {
                        // FUTURE: other cart item types (e.g. kits)
                    }

                });
            }

            this.totalShoppedItems = totalShoppedItems;

            var samples = this.order.samples;
            if (samples != null) {
                $.each(samples,  function(){
                    // set up contents by cart hashes
                    var cartID = this.CART_ID;
                    var cart = self.carts.get(cartID);

                    if (!cart) {
                        self.carts.set(cartID, new Hash());
                        cart = self.carts.get(cartID);
                        cart.set('contents', new Hash());
                    }

                    var id = this['sku.SKU_BASE_ID'] ? this['sku.SKU_BASE_ID'] : this.COLLECTION_ID;
                    cart.get('contents').set(id, this.ITEM_QUANTITY);

                    // set up order contents hash (spans carts)
                    if (this.itemType.toLowerCase() == 'sampleitem') {
                        var key = this['sku.SKU_BASE_ID'];
                        var qty = this.ITEM_QUANTITY;
                        self.order.sample_contents.set(key,qty);
                    } else {
                        // other item types (are likely errors)
                    }
                });
            }

            // original:
            // if (self.setCookie) self.setCookie();
            // generic.events.fire({event:'cart:countsUpdated'});
            // generic.events.fire({event:'cart:updated'});

            //if (self.setCookie) self.setCookie();
            /**
             * @event cart:countsUpdated
             */
            //generic.events.fire({event:'cart:countsUpdated'});

        }
    },
    /**
     * @inner Api class with all the methods to handle cart
     */
    API = {
        initialize: function(args) {
             $.extend(this, args);
        },
        /**
         * @public getCartTotals
         */
        getCartTotals: function() {

            var cookie = Cookie("cart");
            if (cookie && cookie!==null) {
               // console.log("generic.cart.getCartTotals cookie: "+Object.toJSON(cookie));
               $.extend(this, cookie);

               /**
                * @events cart:countsUpdated
                */
               // generic.events.fire({event:'cart:countsUpdated'});
            } else {
               // console.log("generic.cart.getCartTotals !cookie");
               this.getCart();
            }

        },
        /**
         * @public setCookie
         */
        setCookie: function() {
            // console.log("generic.cart.setCookie "+this.totalItems);
            var s  = {
                totalItems: this.totalItems
            }
            s = JSON.stringify(s);
            Cookie("cart",s, {path:"/"});
        },
        /**
         * @public getCart
         * @returns id of updated cart
         */
        getCart: function(args) {

            //console.log("generic.cart.getCart");
            var self = this;

            if (args != null && args.pageDataKey) {
                var pageData = generic.page_data(args.pageDataKey);
                if (pageData.get("rpcdata")) {
                    // console.log( "cart page data found!" );
                    self._updateCartData(pageData.get("rpcdata"));
                    return;
                }
            }

            var params = {};
            $.extend ( params, self.transactionParams.transactionFields );
            $.extend ( params, self.transactionParams.paymentFields);
            $.extend ( params, self.transactionParams.orderFields);

             var id = generic.jsonrpc.fetch({
                method : 'trans.get',
                params: [params],
                onSuccess:function(jsonRpcResponse) {
                    self._updateCartData(jsonRpcResponse.getValue());
                },
                onFailure: function(jsonRpcResponse){
                    //jsonRpcResponse.getError();
                    console.log('Transaction JSON failed to load');
                }
            });
            return id;

        },
        /**
         * @public updateCart
         *
         * @param {object} onSuccess, onFailure callbacks
         *
         * @returns {number} incremented id uniquely identifying internal operations
         */
        updateCart: function(args){

            // console.log("cart.updateCart: "+Object.toJSON(args.params));
            if (!args.params) return null;

            var self = this;
            var onSuccess = args.onSuccess || new (function(){})(); // native empty function
            var onFailure = args.onFailure || new (function(){})(); // prev: prototype.emptyFunction

            var itemType = args.params.itemType || "cart"; //e.g. cart, collection, giftcard etc
            var id = self.itemTypes[itemType].id;
            var method = 'rpc.form';

            var params = {
                '_SUBMIT': self.itemTypes[itemType]["_SUBMIT"]
            };  // not-yet args.params

            //id // single id or collection id based on sku array from params
            if (id == 'SKU_BASE_ID') {
                // params[id] = (args.params.skus.length == 1) ? args.params.skus[0] : args.params.collectionId; //MK collections array syntax correct?
                params[id] = args.params.skus;
            } else if (id == 'COLLECTION_ID') {
                params[id] = args.params.collectionId;
            }

            //qty
            if (args.params.INCREMENT && args.params.INCREMENT>=0) {
               //currently +1 will be added regardless of INCREMENT's actual value
               //backend requires QTY property to exist but it will not be used
                params["INCREMENT"] = args.params.INCREMENT;
                params["QTY"] = 1;
            } else if (args.params.INCREMENT && args.params.INCREMENT<0) {
                //decrements qty by -1
            } else if (args.params.QTY && args.params.QTY>=0) {
                params["QTY"] = args.params.QTY;
            }

            //offer code
            if (args.params.OFFER_CODE && args.params.OFFER_CODE.length>0) {
                params['OFFER_CODE'] = args.params.OFFER_CODE;
            }

            //favorites
            if (args.params.action && args.params.action.length > 0) {
                params['action'] = 'add';
            }

            //kit
            if (args.params.action && args.params.action == 'save') {
                params['action'] = 'save';
            }

            //replenishment
            if (args.params.REPLENISHMENT_FREQ && args.params.REPLENISHMENT_FREQ >= 0) {
                params['REPLENISHMENT_FREQ'] = args.params.REPLENISHMENT_FREQ;
            }
            if (args.params.add_to_cart && args.params.add_to_cart != 0) {
                params['add_to_cart'] = args.params.add_to_cart;
            }

            //giftcard
            if (args.params.ITEM_TYPE && args.params.ITEM_TYPE == 'GiftCard') {
                $.extend(params, args.params);
            }

            // targeting of the correct cart is still missing (and important to get right)
            // cart id if we are adding to something other than the default cart
            if (args.params.cart_id && (args.params.cart_id != self.defaultCartId)) {
                params['CART_ID'] = args.params.cart_id;
            }

            //method
            if (args.params.method && args.params.method.length > 0 ) {
                method = args.params.method;
            }

            // Save which catId the prod was displayed in
            if (args.params.CAT_BASE_ID && args.params.CAT_BASE_ID.length > 0) {
                params["CAT_BASE_ID"] = args.params.CAT_BASE_ID;
            }

            var id = JsonRpc.fetch({
                "method" : method,
                "params" : [params], // [{}]
                "onSuccess": function(jsonRpcResponse){

                    var data = jsonRpcResponse.getData();
                    var cartResultObj = jsonRpcResponse.getCartResults();
                    //load data
                    if (data && data["trans_data"]) {
                        self._updateCartData(data["trans_data"]);
                    }
                    if (args.params.itemType == 'cart') {
                         // $(document).trigger("cart.updated", [cartResultObj]);
                    };
                    if (args.params.itemType == 'favorites') {
                        /**
                         * @event favorites:updated
                         */

                        $(document).trigger("favorites.updated", [jsonRpcResponse]);
                    };
                    if (args.params.itemType == 'kit') {
                        /**
                         * @event kit:updated
                         */

                        $(document).trigger("kit.updated", [jsonRpcResponse]);
                    };
                    if (args.params.itemType == 'replenishment') {
                      $(document).trigger('quickshop.add');
                    };
                    onSuccess(jsonRpcResponse);

                },
                "onFailure": function(jsonRpcResponse){
                    onFailure(jsonRpcResponse);
                }
            });

            return id;

        },
        /**
         * @public getItemQty
         * @returns {number}
         */
        getItemQty : function(baseSkuId) {
            if (!this.order.items) return 0;
            /* prototype js code:
            var lineItem = this.order.items.find( function (line) {
                return line['sku.SKU_BASE_ID'] ==  baseSkuId;
              });
            if (!lineItem) {
                return 0;
            }
            return lineItem.ITEM_QUANTITY;
            */
            for(i in this.order.items) {

                if( i['sku.SKU_BASE_ID'] == baseSkuId ) {

                    var lineItem = i;
                    break;
                }

            }
            if(!lineItem) return 0;
            return lineItem.ITEM_QUANTITY;
        },
        /**
         * @public getBaseSkuIds
         * @returns {array}
         */
        getBaseSkuIds: function() {  //MK: what is this used for?
            //console.log("generic.cart.getBaseSkuIds: "+this.order.items);
            /* prototype js code:
            if (!this.order.items) return new Hash();
            var baseSkuIds = this.order.items.pluck( 'sku.SKU_BASE_ID' ); //MK what about giftcards/collections?
            return baseSkuIds;
            */
            if (!this.order.items) return new Hash();
            var baseSkuIds = [];
            for(i in this.order.items) {
                baseSkuIds.push(i['sku.SKU_BASE_ID']);
            }
            return baseSkuIds;
        },
        /**
         * @public getSubtotal
         * @returns {number}
         */
        getSubtotal: function() {
            var lineItems = this.order.items;
            if (!this.order.items) return 0;
            var subtotal = 0;
            for (var i=0, len = lineItems.length; i<len; i++) {
                var lineItem = lineItems[i];
                subtotal += (lineItem.UNIT_PRICE + lineItem.UNIT_TAX) * lineItem.ITEM_QUANTITY;
            }
            return subtotal;
        },
        /**
         * @public getTotalShoppedItems
         * @returns {number}
         */
        getTotalShoppedItems: function(){ //products and gift cards
           /** var ttl = 0;
            var items = this.order.items;
            if (items != null) {
                items.each(function(item){
                    if (item && item.ITEM_QUANTITY) {
                        ttl += item.ITEM_QUANTITY;
                    }
                });
            }
            return ttl;**/
            return this.totalShoppedItems;
        },
        /**
         * @public getTotalSamples
         * @returns {number}
         */
        getTotalSamples: function() {
             var ttl = 0;
             var samples = this.order.samples;
                if (samples != null) {
                    samples.each(function(item){
                        ttl += item.ITEM_QUANTITY;
                    });
            }
            return ttl;
        },
        /**
         * @public getTotalItems
         * @returns {number}
         */
        getTotalItems: function(){
           // return this.getTotalShoppedItems() + this.getTotalSamples();
           return this.totalItems;
         }

    };

    cart = $.extend(cart,API,Ops,CartConstants,CartData,Containers,Properties);

    var extra = {
        sample : function(){alert('sample');}
    }

    return function(){

        if(cart) { return cart; }

        // initial and only-time singleton reference
        cart = $.extend(this,cart);
        cart.api = extra.sample;

    };

}() ) ;

generic.checkout.cart = new generic.checkout.Cart();

$(document).on('perlgem.cart.addItem', function(e, skuBaseId, payload) {
  payload = typeof payload !== 'undefined' ? payload : {};
  const args = {};
  args.skuBaseId = skuBaseId;
  args.qty = payload.quantity ? payload.quantity : 1;
  args.CAT_BASE_ID = payload.CAT_BASE_ID ? payload.CAT_BASE_ID : '';

  const frequency = payload.replenishment ? payload.replenishment : null;
  if (!!frequency) {
    args.REPLENISHMENT_FREQ = frequency;
  }
  prodcat.ui.addToCart(args);
});

$(document).on('addToCart.success', function() {
  if (ServiceBus && ServiceBus.emit && ServiceBusTopics && ServiceBusTopics.events && ServiceBusTopics.events.CART_UPDATED) {
    ServiceBus.emit(
      ServiceBusTopics.events.CART_UPDATED
    );
  }
});

$(document).on('addToCart.failure', function() {
  if (ServiceBus && ServiceBus.emit && ServiceBusTopics && ServiceBusTopics.events && ServiceBusTopics.events.CART_FAILURE) {
    ServiceBus.emit(
      ServiceBusTopics.events.CART_FAILURE
    );
  }
});

})(
  jQuery,
  window.generic || {},
  ServiceBus = window.GlobalServiceBus || {},
  ServiceBusTopics = window.ServiceBusTopics || {}
);

(function(generic) {

generic.cookie = function(/*String*/name, /*String?*/value, /*.__cookieProps*/props) {
  var c = document.cookie;
  if (arguments.length == 1) {
    var matches = c.match(new RegExp("(?:^|; )" + name + "=([^;]*)"));
    if (matches) {
      matches = decodeURIComponent(matches[1]);
      try {
        return jQuery.parseJSON(matches); //Object
      } catch(e) {
        return matches; //String
      }
    } else {
      return undefined;
    }
  } else {
    props = props || {};
    // FIXME: expires=0 seems to disappear right away, not on close? (FF3)  Change docs?
    var exp = props.expires;
    if (typeof exp == "number"){
      var d = new Date();
      d.setTime(d.getTime() + exp*24*60*60*1000);
      exp = props.expires = d;
    }
    if (exp && exp.toUTCString) {
      props.expires = exp.toUTCString();
    }

    value = encodeURIComponent(value);
    var updatedCookie = name + "=" + value;

    for (propName in props) {
      updatedCookie += "; " + propName;
      var propValue = props[propName];
      if (propValue !== true) {
        updatedCookie += "=" + propValue;
      }
    }

    document.cookie = updatedCookie;
  }
};

})(window.generic || {});

(function($, generic) {

generic.errorStateClassName = 'error';

/**
 * This method displays error messages. It takes an array of error objects and a UL node
 * as parameters. If the UL is not spuulied, it will attempt to find a <UL class="error_messages">
 * in the DOM. It will then attempt to insert one directly after a <DIV id="header"> (If no header
 * is found, the method exits.) All the LI child nodes (previous messages) of the UL are hidden.
 * The text property of each error Hash is then displayed as an LI.
 * This method can also alter the style of the input elements that triggered the error.
 * The tags property in an error hash must be an array that contains a string starting with
 * "field." If the optional formNode parameter is supplied, this form node will be
 * searched for the field, and that field will be passed to the generic.showErrorState method.
 * @example
 * var errArray = [
 *      {
 *          "text": "Last Name Alternate must use Kana characters.",
 *          "display_locations": [],
 *          "severity": "MESSAGE",
 *          "tags": ["FORM", "address", "field.last_name_alternate"],
 *          "key": "unicode_script.last_name_alternate.address"
 *      },
 *      {
 *          "text": "First Name Alternate must use Kana characters.",
 *          "display_locations": [],
 *          "severity": "MESSAGE",
 *          "tags": ["FORM", "address", "field.first_name_alternate"],
 *          "key": "unicode_script.first_name_alternate.address"
 *      }
 *  ];
 * var listNode = $$("ul.error_messages")[0];
 * generic.showErrors(errArray, listNode);
 * @param {Array} errorObjectsArray Array of error hashes.
 * @param {DOM Node UL} errListNode UL element in which the error messages will be displayed.
 * @param {DOM Node} formNode Form element (or any container node) that contains the inputs
 * to be marked as being in an error state.
 */
generic.showErrors = function(errorObjectsArray, errListNode, formNode) {

    var ulNode = errListNode != null ? errListNode : $("ul.error_messages");
    // prototype version acted on a single node. This could be a list
    // so cut it down to avoid redundant messaging in places. i.e - signin
    ulNode = $(ulNode[0]);

    // TEST that pre-exisiting ul.error_messages is selected as container
    if ( $("ul.error_messages").length == 0 ) {
        var header = $("div#header");
        if (  header.length == 0  ) {
            return null;
            // TEST that DOM w/o div#header gets no error list (no ulNode parameter in method call)
        } else {
            $("<ul class='error_messages'></ul>").insertAfter(header);
            ulNode =  $(".error_messages");
            // TEST that DOM with div#header adds ul.error_messages after div#header)
        }
    }
    var errListItemNodes = ulNode.children("li");

    errListItemNodes.hide();
    // TEST that pre-exisiting, visible ul.error_messages LI's are hidden
    if (errorObjectsArray.length > 0 ){
        // hide all error states on fields
        formNode = $(formNode);
        var inputNodes = formNode.children("input, select, label");
        inputNodes.each( function () {
            generic.hideErrorState(this);
        });
        // TEST setup: input, select, label elements in formNode have class name "error"
        // test that the class name gets removed from those elements
    }
    for (var i=0, len=errorObjectsArray.length; i<len; i++) {
        var errObj = errorObjectsArray[i];
        var errKey = errObj.key;
        var errListItemNode = [];
        if (errKey) {
            var regEx = new RegExp(errKey);
            // try to find LI whose ID matches the error key
            errListItemNode = errListItemNodes.filter( function() {
                return regEx.test(this.id);
            });
        }

        if (errListItemNode.length > 0) {
            // TEST setup: LI with id that matches error key is already in UL, hidden.
            // Test that the LI gets shown when matching key is found amoung error objects.
            errListItemNode.show();
        } else {
            // TEST setup: no matching LI is in UL. LI gets created with matching key and added to UL.
            errListItemNode = $("<li/>");
            errListItemNode.html(errObj.text);
            ulNode.append( errListItemNode );

        }
        if (errObj.displayMode && errObj.displayMode === "message") {
            // TEST setup: error object has displayMode="message"
            // Test that matching LI gets class name "message"
            errListItemNode.addClass("message");
        }
        if (errObj.tags && $.isArray(errObj.tags)) {
            // search through error objects, show error state for any tagged with "field.[NAME]"
            var fieldPrefixRegEx = /^field\.(\w+)$/;
            for (var j = 0, jlen = errObj.tags.length; j < jlen; j++) {
                var tag = errObj.tags[j];
                var reResults = tag.match(fieldPrefixRegEx);
                if ( reResults && reResults[1] ) {
                    var fieldName = reResults[1].toUpperCase();
                    var inputNode = $("input[name=" + fieldName + "], select[name=" + fieldName + "]" , formNode);
                    if (inputNode.length > 0) {
                        generic.showErrorState(inputNode[0]);
                        var labelNode = $("label[for=" + inputNode[0].id + "]", formNode);
                        generic.showErrorState(labelNode[0]);
                    }
                }
            }
            // TEST setup: error object has "tags" = ["field.last_name"]; formNode contains a label & an input tag with name=last_name
            // Test that the tags get className "error"
        }
    }
    ulNode.show();
    ulNode.addClass("error_messages_display");
    // TEST ulNode should be visible & have classname = error_messages_display
};

generic.showErrorState = function( /* DOM Node */ inputNode) {
    if (!inputNode || !generic.isElement(inputNode)) {
        return null;
    }
    $(inputNode).addClass(generic.errorStateClassName);
}

generic.hideErrorState = function( /* DOM Node */ inputNode) {
    if (!inputNode || !generic.isElement(inputNode)) {
        return null;
    }
    $(inputNode).removeClass(generic.errorStateClassName);
}

})(jQuery, window.generic || {});

(function($, generic) {

generic.forms = {
  select : {
    addOption: function(args) {
      if (!args) return;
      var val = args.value;
      var label = args.label || val;
      var opt = '<option value="' + val + '">' + label + '</option>';
      args.menuNode.append(opt);
    },
    setValue: function(args) {
      var idx = 0;
      for (var i = 0, len = args.menuNode[0].options.length; i < len; i++) {
        if (args.value == args.menuNode[0].options[i].value) {
          idx = i;
          break;
        }
      }
      args.menuNode[0].selectedIndex = idx;
    }
  }
};

})(jQuery, window.generic || {});

(function($, generic, rb) {

generic.rb = generic.rb || {};

/**
* This method provides access to resource bundle values that have been
* written to the HTML in JSON format. The file that outputs these values
* must be included in the .html as a script tag with the desired RB name
* as a query string paramter.
*
* @class ResourceBundle
* @namespace generic.rb
*
* @memberOf generic
* @methodOf generic
* @requires generic.Hash (minimal functional replication of Prototype Hash Class)
*
* @example Inline data
*
*    <script src="/js/shared/v2/internal/resource.tmpl?rb=account"></script>
*
* @example Script retrival of data values
*
*    var myBundle = generic.rb("account");
*    myBundle.get("err_please_sign_in");
*
*
* @param {String} rbGroupName name of resource bundle needed
*
* @returns An object that provides the main get method
*
*/
generic.rb = function(rbGroupName) {

    var findResourceBundle = function(groupName) {

        if (groupName && rb) {

            var rbName = groupName;
            var rbHash = generic.Hash(rb[rbName]);
            if (rbHash) {
                return rbHash;
            } else {
                return $H({});
            }
        } else {
            return $H({});
        }

    };

    var resourceBundle = findResourceBundle(rbGroupName);

    var returnObj = {
        /**
        * @public This method will return the value for the requested Resource Bundle key.
        * If the key is not found, the key name will be returned.
        *
        * @param {String} keyName key of desired Resource Bundle value
        */
        get: function(keyName) {
            if ( !generic.isString(keyName) ) {
                return null;
            }
            var val = resourceBundle.get(keyName);
            if (val) {
                return val;
            } else {
                return keyName;
            }
        }
    };

    return returnObj;

};

})(jQuery, window.generic || {}, window.rb || {});

var generic = generic || {};

(function($) {

/**
 * Template.js
 *
 * @memberOf generic
 *
 * @class TemplateSingleton
 * @namespace generic.template
 *
 * @requires object literal with parameters
 *
 * @param path attribute as a literal key is required
 * @example "/templates/cart-overlay.tmpl",
 *
 * @param {string} templateString takes first priority
 * @example templateString:'#{product.url} some-page-markup-with-#{product.url}'
 *
 * @param {boolean} forceReload
 *
 * @param {function} callback
 * @example
 *
 * callback:function(html) {
 *    // Front-End Resolution
 *    $('#container').html(html);
 * }
 *
 * @param {object} query object hash with object-literals, array-literals that can be nested
 * @example example structure
 * query: {
 *    a:'',b:{},c:[],d:{[]} // keys rooted to named parent if object or array-objects are nested
 * }
 *
 * @param {object} Hash of string-literals with string values that map to the template
 * @example
 *
 * object: {
 *    'product.PROD_RGN_NAME':'replacement',
 *    SOME_VAR:'replacement'
 * }
 *
 * @example Usage
 *
 * generic.template.get({
 *    path:"/some/path/to/template.tmpl",
 *    ...
 * });
 *
 * @param {HTML} (optional) Markup based inline template
 * @required The path attribute must match the path key passed to the get method.
 *
 * @example Inline Template Example
 *
 * <!-- -------------------------- Inline Template ------------------------------ -->
 *
 * <script type="text/html" class="inline-template" path="templates/foo.tmpl">"
 *         <div>#{FIRST_NAME}</div>
 *         <div>#{SECOND_NAME}</div>
 * </script>
 *
 * Inline Templates : Valid inline template via script tag in this format, aside
 * from the templateString parameter, will be the first candidate for the template,
 * then the cache, then ajax.
 *
 *
 * @returns {object} An object that refers to a singleton which provides
 * the primary get api method.
 *
 */


generic.template  = ( function() {

    var that = {};
    var templateClassName = ".inline-template";
    var templates = {};

    /**
     * This method loads a pre-interpolation template into the object's internal cache. This cache is checked before attempting to pull the template from the DOM or load it via Ajax.
     * @param (String) key The name that is used to retrieve the template from the internal cache. Typically mathces the path for Ajax-loaded templates.
     * @param (String) html The non-interpoltaed content of the template.
     * @returns (Strin) the HTML that was originally passed in
     * @private
     */
    var setInternalTemplate = function(key, html) {
        templates[key] = html;
        return html;
    };

    var getInternalTemplate = function(key) {
        return templates[key];
    };

    var returnTemplate = function(args) {
        if (typeof args.object === "object") {
            var html = interpolate(args.template, args.object);
        }else{
			var html = args.template;
		}
        if (typeof args.callback === "function") {
            args.callback(html);
        }
    };

    var interpolate = function(template, obj) {
        var obj = obj || {};
        var tmpl = template, Lre = new RegExp("\#\{"), Rre = new RegExp("\}"), tmplA = [], temp, lft, rght;

        tmplA = tmpl.replace(/[\r\t\n]/g," ").split(Lre); // array of (.+?)} with '}' marking key vs rest of doc

        var returnString = "";
        for(var x = 0; x < tmplA.length; x++) {
            var chunk = tmplA[x];
            var splitChunk = chunk.split(Rre);

			// FIXME TODO: Embarrassingly ham handed approach to setting url_domain template variable for IE (bug i73662)
			//  Needs someone more familiar with javascript to find out why this error only occurs in IE
			//	 with the url_domain object value set anywhere but here (setting it elsewhere works fine in FF)
			if (splitChunk[0] == 'url_domain') {
				splitChunk[1] = 'http://' + document.location.hostname;
			}
            if (typeof splitChunk[1] !== "undefined") { // close tag is found
                // First check array notation for property names with spaces
                // Then check object notation for deep references
                var valueToInsert = eval("obj['" + splitChunk[0] +"']" ) || eval("obj." + splitChunk[0] );
                if (typeof valueToInsert === "undefined" || valueToInsert === null) {
                    valueToInsert = '';
                }
                chunk = valueToInsert.toString() + splitChunk[1];
            }
            returnString += chunk;
        }
        return returnString;
    };

    that.get = function( args ) {
        var key = args.path;
        var callback = args.callback;
        var forceReload = !!args.forceReload;
        var objectParam = args.object;
        var template = getInternalTemplate(key);

        if (template && !forceReload) {  // internal template found and OK to use cache
            returnTemplate({
                template: template,
                object: objectParam,
                callback: args.callback
            })
        } else {  // no internal template found or not OK to use cache
            // attempt to retrieve from DOM
            var matchingTemplateNode = null;
            $(templateClassName).each( function() {
                if ( $(this).html() && ( $(this).attr("path")==key) ) {
                    matchingTemplateNode = this;
                }
            });
            if (matchingTemplateNode) { // inline template found in DOM
                template = setInternalTemplate( key, $(matchingTemplateNode).html() );
                returnTemplate({
                    template: template,
                    object: args.object,
                    callback: args.callback
                })
            } else { // not found inline


                $.ajax({
                    url: key,
                    context: this, // bind (.bind onSuccess callback)
                    data: args.urlparams,
                    success: function(data, textStatus, jqXHR){
                        template = setInternalTemplate( key, jqXHR.responseText);
                        returnTemplate({
                            template: template,
                            object: args.object,
                            callback: args.callback
                        })
                    }
                });
            }
        }

    };

    return that;

})();
//
// generic.TemplateSingleton = (function(){
//
//     /**
//      * @private singleton reference
//      */
//     var singleton;
//
//     /**
//      * @inner Template constructor
//      * @constructs Template object
//      */
//     var Template = function ( template, pattern ) {
//         this.template = template?template:'';
//         this.readyState = template?1:0;
//         this.pattern = pattern?pattern:new RegExp("\#\{(.+?)\}");
//         this.queue = new Array();
//
//         /**
//          * @private
//          */
//         var A = {
//             evaluate : function(replacements){
//                 var tmpl = this.template, Lre = new RegExp("\#\{"), Rre = new RegExp("\}"), tmplA = [], temp, lft, rght;
//
//             // reference : ob.replace(/[\r\t\n]/g," ").split("<").join("Y").split(">").join("X");
//             tmplA = tmpl.replace(/[\r\t\n]/g," ").split(Lre); // array of (.+?)} with '}' marking key vs rest of doc
//
//             for(var x = 0; x < tmplA.length; x++) {
//
//                 // Array.split returns differently for IE, test for undefined
//                 lft = (replacements[tmplA[x].split(Rre)[0]]===undefined)?'':replacements[tmplA[x].split(Rre)[0]]
//                 rght = (tmplA[x].split(Rre)[1]===undefined)?'':tmplA[x].split(Rre)[1];
//
//                 tmplA[x] = lft + rght;
//
//                 }
//                 tmpl = tmplA.join('');
//
//                 return tmpl;
//
//             },
//             Pattern : new RegExp("\#\{(.+?)\}")
//         }
//
//         $.extend(this,A,{
//             //test: function(){alert('test at prototype level');}, // prototype set as object, not a constructor
//             load: function(template) {
//                 this.template = template.toString();
//                 this.readyState = 1;
//                 this.onReadyState();
//             },
//             evaluateCallback: function (options) {
//                 this.options = {
//                     object: {},
//                     callback: function () {}
//                 };
//                 this.options = $.extend(this.options, options || { });
//
//                       /**
//                        * @private This is tied to templateString. If passed,
//                        * then onReadyState queue bypassed, and control goes straight
//                        * to the callbackEvaluation via readState true
//                        */
//                     if (this.readyState) {
//                         this.options.callback(this.evaluate(this.options.object));
//
//                     } else {
//                         this.queue.push({
//                             qtype: 'callback',
//                             obj: this.options.object,
//                             fnc: this.options.callback
//                         });
//                     }
//                 return;
//             },
//             // Asynchronous to .evaluateCallback
//             onReadyState: function () {
//                 while (q = this.queue.shift()) {
//                     var object = q.obj;
//                     var qtype = q.qtype;
//                     var callback = q.fnc;
//
//                     callback(this.evaluate(object));
//                 }
//             }
//         });
//     }
//
//     /**
//      * @description Single object with main method that controls for
//      * the switch among templateString, inline, cache, ajax is delegated.
//      *
//      * @inner Constructor
//      * @constructs an object with main method namespace
//      * @class GetFunctionObject
//      */
//     var GetFunctionObject = (new function() {
//
//         /**
//          * @private
//          */
//         var defaults = { useInline:true, templateCssClass : ".inline-template" }, _object  = []; // internal prop
//
//         this.debug = function() { var msg = 'private var defaults : '+defaults.useInline; alert(msg); }
//
//         /**
//          * @private
//          * @function primary function (method) as value bound to public get api method
//          */
//         this.main = function (params) {
//
//             /**
//              * @private
//              */
//             var key = params.path, query = params.query,
//                 forceReload = params.forceReload || false,
//                 templateString = params.templateString || false,
//                 useInline, templateClassName;
//
//             // if no templateString-override, then setup for inline script tag template
//             if(!templateString) {
//                 useInline = (params.useInline)?params.useInline:defaults.useInline;
//                 templateClassName = (params.templateClassName)?params.templateClassName:defaults.templateCssClass;
//             }
//             /* these controls currently closed
//             if(params.useInline && params.templateClassName) {
//                 defaults.useInline = params.useInline;
//                 defaults.templateClassName = params.templateClassName;
//             }
//             */
//
//             // Case 1: Brand New : forceReload, new query, and no template yet
//             if (typeof _object[key] != "undefined" && !forceReload && !query) {
//                 return _object[key];
//             }
//             _object[key] = new Template();
//             //this._object[key].test(); // hits new inner Class of Template
//
//             // Case 2: Template string directly passed
//             if (templateString) {
//                 _object[key].load(templateString);
//                 return _object[key].evaluateCallback(params);
//             }
//             var url = key;
//             if (query) {
//                 var q = $H(query);
//                 var queryString = q.toQueryString();
//                 url += "?" + queryString;
//             }
//             // Attempt to Use Inline
//             if(useInline) {
//                 $(templateClassName).each(function(){
//                     if($(this).html()&&($(this).attr("path")==key)) {
//                      alert($(this).html());
//                         _object[key].load( $(this).html() );
//                     }
//                 });
//             }else{
//              // load asynchronously and move onto evaluateCallback
//              $.ajax({
//                  url: url,
//                  context: this, // bind (.bind onSuccess callback)
//                  data: params.urlparams,
//                  success: function(data, textStatus, jqXHR){
//                      _object[key].load(jqXHR.responseText);
//                  }
//              });
//          }
//              _object[key].evaluateCallback(params);
//
//         return this;
//         }
//     }());
//
//     var PublicInterfaceMapper = { get : GetFunctionObject.main, sample : GetFunctionObject.debug };
//     var API = $.extend(PublicInterfaceMapper,GetFunctionObject);
//
//     return function () {
//
//         // Return Same Obj
//         if(singleton) { return singleton; }
//
//             // Extra api
//             singleton = $.extend(this,API);
//             singleton.api = API.sample;
//
//         //alert('return singleton; //* should only see this once *// ');
//         };
//     }());
//
// generic.template = new generic.TemplateSingleton();
})(jQuery);
